import { Notification } from 'element-ui';

export function setRem() {
  let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
  const baseSize = 16;  // 默认基础字体大小
  const targetResolution = 1920;  // 目标分辨率
  let defaultFontSize = '';
  if (htmlWidth < targetResolution) {
    defaultFontSize = htmlWidth / 120 + 'px';
  } else if (htmlWidth >= targetResolution) {
    defaultFontSize = baseSize + 'px';
  }

  document.documentElement.style.setProperty('--global-font-size', `${defaultFontSize}`)
}
document.addEventListener('DOMContentLoaded', setRem)

/**
 * @param {date} time 需要转换的时间
 * @param {String} fmt 需要转换的格式 如 yyyy-MM-dd、yyyy-MM-dd HH:mm:ss
 */
export function formatTime(time, fmt) {
    if (!time) return '';
    else {
      const date = new Date(time);
      const o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'H+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
        'q+': Math.floor((date.getMonth() + 3) / 3),
        S: date.getMilliseconds(),
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + '').substr(4 - RegExp.$1.length)
        );
      for (const k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1
              ? o[k]
              : ('00' + o[k]).substr(('' + o[k]).length)
          );
        }
      }
      return fmt;
    }
}

export const notifyUser = (message, type = 'info') => {
  Notification({
    title: type.charAt(0).toUpperCase() + type.slice(1),
    message: message,
    type: type,
    duration: 5000
  });
}
import Axios from "./request";
// 获取商品列表
export const shopGoodsList = (chain_id, limit, page, query) =>
  Axios.get(
    `goods/getlist?chain_id=${chain_id}&limit=${limit}&page=${page}&query=${query}`
  );
// 获取oss签名
export const getKey = () => Axios.get("getkey");
// 添加商品
export const addGoods = (postData) => Axios.post("goods/addGoods", postData);
// 修改商品状态
export const changeStatus = (postData) =>
  Axios.post("goods/changeStatus", postData);
// 删除商品
export const delGoods = (postData) => Axios.post("goods/delGoods", postData);
// 登录
export const Login = (postData) => Axios.post("login", postData);
// ai平台登录
export const aiLogin = (postData) => Axios.post("aiLogin", postData);
// ai管理后台登录
export const aiAdminLogin = (postData) => Axios.post("aiAdminLogin", postData);
// 注册大嘴鸟ai
export const register = (postData) => Axios.post("register", postData);
// 获取商品二维码
export const qrCode = (postData) => Axios.post("goods/qrCode", postData);
// 获取员工二维码
export const UserQrCode = (postData) => Axios.post("user/qrCode", postData);
// 获取订单列表
export const shopOrderlist = (chain_id, limit, page, query) =>
  Axios.get(
    `order/getlist?chain_id=${chain_id}&limit=${limit}&page=${page}&query=${query}`
  );
// 获取下单用户信息
export const getMember = (shop_member_add_id) =>
  Axios.get(`order/getMember?shop_member_add_id=${shop_member_add_id}`);
// 获取AI用户详细信息
export const aiUserMessage = (shop_user_id) =>
  Axios.get(`user/aiUserMessage?shop_user_id=${shop_user_id}`);
// 获取机构列表
export const shopChainlist = (chain_id, limit, page, query) =>
  Axios.get(
    `chain/getlist?chain_id=${chain_id}&limit=${limit}&page=${page}&query=${query}`
  );
// 获取AI学校列表
export const aiChainlist = (limit, page, query) =>
  Axios.get(`chain/aigetlist?limit=${limit}&page=${page}&query=${query}`);
// 注册时获取AI学校信息
export const aigetlistQuery = (query) =>
  Axios.get(`chain/aigetlistQuery?query=${query}`);
// 获取个人中心
export const getAiUserDetails = () => Axios.get("chatGPT/getAiUserDetails");
// 一键发布简历大赛成绩
export const submitResume = () => Axios.post("chatGPT/submitResume");
// 修改用户信息
export const insertUserDetails = (postData) =>
  Axios.post("chatGPT/insertUserDetails", postData);
// 学校认证
export const insertSchoolDetails = (postData) =>
  Axios.post("chatGPT/insertSchoolDetails", postData);
// 企业认证
export const insertEnterpriseDetails = (postData) =>
  Axios.post("chatGPT/insertEnterpriseDetails", postData, {
    headers: {
      "Content-Type": "application/json",
    },
  });

// 企业重新认证
export const delChainMid = (postData) =>
  Axios.post("chatGPT/delChainMid", postData);
// 企业认证审核
export const updateChainMid = (postData) =>
  Axios.post("chatGPT/updateChainMid", postData, {
    headers: {
      "Content-Type": "application/json",
    },
  });

// 检查企业是否存在
export const getEnterpriseDetails = (education) =>
  Axios.get(`chatGPT/getEnterpriseDetails?education=${education}`);
// 获取用户列表
export const shopGetlist = (chain_id, limit, page, query, select_chain_id) =>
  Axios.get(
    `user/getlist?chain_id=${chain_id}&limit=${limit}&page=${page}&query=${query}&select_chain_id=${select_chain_id}`
  );
// 获取AI用户列表
export const aiUserlist = (limit, page, query, select_chain_id) =>
  Axios.get(
    `user/aiUserlist?limit=${limit}&page=${page}&query=${query}&select_chain_id=${select_chain_id}`
  );
// 获取商品详情
export const getDetails = (shop_goods_id) =>
  Axios.get(`goods/getDetails?shop_goods_id=${shop_goods_id}`);
// 后台获取简历列表
export const getAdminResume = () => Axios.get("chatGPT/getAdminResume");
// 后台获取简历排名
export const getRanking = (resume_id) =>
  Axios.get(`chatGPT/getRanking?resume_id=${resume_id}`);
// 批量添加AI用户
export const aiUploadMessage = (postData) =>
  Axios.post("user/aiUploadMessage", postData);
// 批量添加AI用户
export const updateHearten = (postData) =>
  Axios.post("chatGPT/updateHearten", postData);
// 学生简历打分
export const updateScore = (postData) =>
  Axios.post("chatGPT/updateScore", postData);
// 语音转文字
export const getSubtitle = (postData) =>
  Axios.post("chatGPT/getSubtitle", postData);
// 报名简历大赛
export const insertResume = (postData) =>
  Axios.post("chatGPT/insertResume", postData);
// 积分系统修改目标
export const addResultTarget = (postData) =>
  Axios.post("recommend/addResultTarget", postData);
// 报名简历大赛
export const insertSubmit = (postData) =>
  Axios.post("chatGPT/insertSubmit", postData);
// 在线简历生成简历推荐
export const getAnalysis = (postData) =>
  Axios.post("chatGPT/getAnalysis", postData);
// 添加机构
export const addChain = (postData) => Axios.post("chain/addChain", postData);
// 修改AI学校信息
export const aiAddChain = (postData) =>
  Axios.post("chain/aiAddChain", postData);
// 获取机构详情
export const getResume = (resume_id) =>
  Axios.get(`chatGPT/getResume?resume_id=${resume_id}`);
// 获取机构详情
export const getChainDetails = (shop_chain_id) =>
  Axios.get(`chain/getDetails?shop_chain_id=${shop_chain_id}`);
// 获取AI学校详情
export const aiChainDetails = (shop_user_ai_chain_id) =>
  Axios.get(
    `chain/aiChainDetails?shop_user_ai_chain_id=${shop_user_ai_chain_id}`
  );
// 检查是否存在在线简历
export const checkSubmit = () => Axios.get("chatGPT/checkSubmit");
// 获取在线简历
export const getSubmit = () => Axios.get("chatGPT/getSubmit");
// 删除机构
export const delChain = (postData) => Axios.post("chain/delChain", postData);
// 删除订单
export const delOrder = (postData) => Axios.post("order/delOrder", postData);
// 获取下级机构列表
export const downchain = () => Axios.get("chain/downchain");
// 添加或修改用户信息
export const addUser = (postData) => Axios.post("user/addUser", postData);
// 获取用户详情
export const shopUserDetails = (shop_user_id) =>
  Axios.get(`user/getDetails?shop_user_id=${shop_user_id}`);
// 修改用户状态
export const shopStatus = (postData) =>
  Axios.post("user/changeStatus", postData);
// 删除用户
export const delUser = (postData) => Axios.post("user/delUser", postData);
// 获取课程列表
export const shopAudiolist = (chain_id, limit, page, query) =>
  Axios.get(
    `audio/getlist?chain_id=${chain_id}&limit=${limit}&page=${page}&query=${query}`
  );
// 添加或修改课程信息
export const addAudio = (postData) => Axios.post("audio/addAudio", postData);
// 获取课程详情
export const shopAudioDetails = (shop_lesson_id) =>
  Axios.get(`audio/getDetails?shop_lesson_id=${shop_lesson_id}`);
// 删除课程
export const delAudio = (postData) => Axios.post("audio/delAudio", postData);
// 获取课程小列表
export const shopAudioSmlist = (shop_goods_id) =>
  Axios.get(`audio/getsmlist?shop_goods_id=${shop_goods_id}`);
// 获取个人业绩统计数据
export const getPersonal = () => Axios.get("echarts/getPersonal");
// 获取个人业绩统计订单数
export const getOrderAll = () => Axios.get("echarts/getOrderAll");
// 获取公司业绩统计订单数
export const getChainOrderAll = () => Axios.get("echarts/getChainOrderAll");
// 获取员工排名
export const getUserRank = () => Axios.get("echarts/getUserRank");
// 获取公司订单计算结果
export const getCompute = () => Axios.get("echarts/getCompute");
// 获取本周销售渠道
export const getArea = () => Axios.get("echarts/getArea");
// 获取课程成交数量排名
export const getGoods = () => Axios.get("echarts/getGoods");
// 个人课程成交数量排名
export const getSelfGoods = () => Axios.get("echarts/getSelfGoods");
// 获取本周与上周销售渠道
export const getAreaAll = () => Axios.get("echarts/getAreaAll");
// 获取发券用户列表
export const getUser = () => Axios.get("discount/getUser");
// 获取券类型
export const getDiscount = () => Axios.get("discount/getDiscount");
// 新增优惠券
export const saveDiscount = (postData) =>
  Axios.post("discount/saveDiscount", postData);
// 获取优惠券列表
export const getDiscountList = (chain_id, limit, page, query) =>
  Axios.get(
    `discount/getDiscountList?chain_id=${chain_id}&limit=${limit}&page=${page}&query=${query}`
  );
// 获取优惠券详情
export const getDiscountDetails = (shop_discount_id) =>
  Axios.get(`discount/getDiscountDetails?shop_discount_id=${shop_discount_id}`);
// 发布优惠券
export const discountStatus = (postData) =>
  Axios.post("discount/discountStatus", postData);
// 删除优惠券
export const discountDel = (postData) =>
  Axios.post("discount/discountDel", postData);
// 新增拼团
export const saveGroup = (postData) => Axios.post("group/saveGroup", postData);
// 获取拼团列表
export const getGroupList = (chain_id, limit, page, query) =>
  Axios.get(
    `group/getGroupList?chain_id=${chain_id}&limit=${limit}&page=${page}&query=${query}`
  );
// 获取拼团详情
export const shopGroupDetails = (shop_group_id) =>
  Axios.get(`group/shopGroupDetails?shop_group_id=${shop_group_id}`);
// 修改拼团状态
export const shopGroupStatus = (postData) =>
  Axios.post("group/shopGroupStatus", postData);
// 删除拼团
export const shopDelGroup = (postData) =>
  Axios.post("group/shopDelGroup", postData);
// 获取拼团价格
export const getSpecification = (goods_id) =>
  Axios.get(`group/getSpecification?goods_id=${goods_id}`);
// 获取角色权限列表
export const getJurisdiction = () => Axios.get(`chain/getJurisdiction`);
// 获取权限列表
export const getjurisdiction = (jurisdiction_name) =>
  Axios.get(`user/getjurisdiction?jurisdiction_name=${jurisdiction_name}`);
// 配置权限
export const addJurisdiction = (postData) =>
  Axios.post("user/addJurisdiction", postData);
// 获取权限详情
export const detailJurisdiction = (shop_jurisdiction_add_id) =>
  Axios.get(
    `user/detailJurisdiction?shop_jurisdiction_add_id=${shop_jurisdiction_add_id}`
  );
// 获取班级列表
export const getClassList = (chain_id, limit, page, query) =>
  Axios.get(
    `class/getlist?chain_id=${chain_id}&limit=${limit}&page=${page}&query=${query}`
  );
// 获取课程小列表
export const getLessonList = () => Axios.get("class/getLessonList");
// 获取音频视频小列表
export const getVideo = (lesson_id) =>
  Axios.get(`class/getLessonVideo?lesson_id=${lesson_id}`);
// 获取班级详情
export const getClassDetails = (shop_class_id) =>
  Axios.get(`class/getDetails?shop_class_id=${shop_class_id}`);
// 添加或修改班级信息
export const shopAddClass = (postData) =>
  Axios.post("class/shopAddClass", postData);
// 删除班级
export const shopDelClass = (postData) =>
  Axios.post("class/shopDelClass", postData);
// 获取学生列表
export const getStudentList = (chain_id, limit, page, query, class_id) =>
  Axios.get(
    `student/getlist?chain_id=${chain_id}&limit=${limit}&page=${page}&query=${query}&class_id=${class_id}`
  );
// 调整学生的班级
export const shopStudentStatus = (postData) =>
  Axios.post("student/changeStatus", postData);
// 删除学生
export const shopDelStudent = (postData) =>
  Axios.post("student/delStudent", postData);
// 添加订单
export const insertOrder = (postData) =>
  Axios.post("order/insertOrder", postData);
// 获取ChatGPT记录
export const getChatGPT = () => Axios.get("chatGPT/getChatGPT");
// 删除ChatGPT记录
export const delChatGPT = (postData) =>
  Axios.post("chatGPT/delChatGPT", postData);
// 提问ChatGPT保存会话
export const insertChatGPT = (postData) =>
  Axios.post("chatGPT/insertChatGPT", postData);
// 修改ChatGPT标题
export const editChatGPT = (postData) =>
  Axios.post("chatGPT/editChatGPT", postData);
// 获取推荐问题
export const getQuestion = (questionsNumber) =>
  Axios.get(`recommend/getQuestion?questionsNumber=${questionsNumber}`);
// 学生答题
export const editanswer = (postData) =>
  Axios.post("recommend/editanswer", postData);
// 生成朋友圈文案
export const getFriends = (postData) =>
  Axios.post("chatGPT/getFriends", postData);
// 初始化历史记录
export const getFriendsHistory = () => Axios.get("chatGPT/getFriendsHistory");
// 检查是否报名简历大赛
export const getResumeId = () => Axios.get("chatGPT/getResumeId");
// 获取问卷
export const getClassify = () => Axios.get("recommend/getClassify");
// 获取问卷问题
export const getQuestionnaire = (student_questionnaire_classify_id) =>
  Axios.get(
    `recommend/getQuestionnaire?student_questionnaire_classify_id=${student_questionnaire_classify_id}`
  );
// 保存回答问卷记录
export const editQuestions = (postData) =>
  Axios.post("recommend/editQuestions", postData);
// 获取问卷结果
export const getResult = (questionnaire_result_id, id) =>
  Axios.get(
    `recommend/getResult?questionnaire_result_id=${questionnaire_result_id}&id=${id}`
  );
// 获取问卷参考内容
export const getTargetMessage = (questionnaire_result_id) =>
  Axios.get(
    `recommend/getTargetMessage?questionnaire_result_id=${questionnaire_result_id}`
  );
// 获取积分页面数据
export const getTargetIntegral = (questionnaire_result_id, filter) =>
  Axios.get(
    `recommend/getTargetIntegral?questionnaire_result_id=${questionnaire_result_id}&filter=${filter}`
  );
// 完成目标
export const updateIsTarget = (postData) =>
  Axios.post("recommend/updateIsTarget", postData);
// 个人中心积分
export const checkIntegral = () => Axios.get("recommend/checkIntegral");
// 获取访谈生涯人物所有标签
export const getPersonageTags = () => Axios.get("chatGPT/getPersonageTags");
// 获取访谈人物聊天记录
export const getPersonageChat = () => Axios.get("chatGPT/getPersonageChat");
// 提问访谈人物会话
export const insertPersonageChat = (postData) =>
  Axios.post("chatGPT/insertPersonageChat", postData);
// 添加新人物
export const insertUserPersonage = (postData) =>
  Axios.post("chatGPT/insertUserPersonage", postData);
// 获取职业规划所有课程
export const getCourseList = (limit, page, chat_stage_grade_id) =>
  Axios.get(
    `chatGPT/getCourseList?limit=${limit}&page=${page}&chat_stage_grade_id=${chat_stage_grade_id}`
  );
// 获取职业规划课程大的类别
export const getStageGradeList = () => Axios.get("chatGPT/getStageGradeList");
// 获取职业规划阶段课程
export const getStageList = (limit, page, chat_stage_id) =>
  Axios.get(
    `chatGPT/getStageList?limit=${limit}&page=${page}&chat_stage_id=${chat_stage_id}`
  );
// 获取标签
export const getBadgeTags = () => Axios.get("chatGPT/getBadgeTags");
// 获取子集标签
export const getBadgeTagsChildren = (interview_badge_tags_id) =>
  Axios.get(
    `chatGPT/getBadgeTagsChildren?interview_badge_tags_id=${interview_badge_tags_id}`
  );
// 获取问题语音
export const getInterviewGPT = (interview_badge_tags_id) =>
  Axios.get(
    `chatGPT/getInterviewGPT?interview_badge_tags_id=${interview_badge_tags_id}`
  );
// 生成面试报告
export const createInterviewGPT = (postData) =>
  Axios.post("chatGPT/createInterviewGPT", postData);
// 获取面试报告
export const getInterviewHistory = (postData) =>
  Axios.post("chatGPT/getInterviewHistory", postData);
// 获取问题语音
export const getQuestionnaireList = (student_questionnaire_classify_id) =>
  Axios.get(
    `recommend/getQuestionnaireList?student_questionnaire_classify_id=${student_questionnaire_classify_id}`
  );
// 获取面试报告
export const insertChatCounselor = (postData) =>
  Axios.post("chatGPT/insertChatCounselor", postData);
//获取全部职位列表
export const getChainJob = () => Axios.get("chatGPT/getChainJob");
//修改职位状态
export const updateJobEnable = (postData) =>
  Axios.post("chatGPT/updateJobEnable", postData);
//获取职位详情
export const getJobDetails = (shop_user_job_id) =>
  Axios.get(`chatGPT/getJobDetails?shop_user_job_id=${shop_user_job_id}`);
//企业端添加或保存职位
export const insertJobData = (postData) =>
  Axios.post("chatGPT/insertJobData", postData);
//企业端删除职位
export const deleteJob = (postData) =>
  Axios.post("chatGPT/deleteJob", postData);
//企业端模糊推荐
export const indistinctRecommend = () =>
  Axios.get("chatGPT/indistinctRecommend");
//企业端精准推荐
export const preciseRecommend = ({ shop_user_job_id, resultMBTI, resultHLD }) =>
  Axios.get(
    `chatGPT/preciseRecommend?shop_user_job_id=${shop_user_job_id}&resultMBTI=${resultMBTI}&resultHLD=${resultHLD}`
  );
//企业端立即沟通
export const firmInsertCommunicate = (postData) =>
  Axios.post("chatGPT/firmInsertCommunicate", postData);
//企业端获取沟通消息
export const getJobMessage = () => Axios.get("chatGPT/getJobMessage");
//企业端获取沟通消息的候选人信息
export const getJobMessageUser = (shop_user_id) =>
  Axios.get(`chatGPT/getJobMessageUser?shop_user_id=${shop_user_id}`);
//发送消息
export const insertDialogue = (postData) =>
  Axios.post("chatGPT/insertDialogue", postData);
//个人端检查是否完成问卷
export const checkCompletion = () => Axios.get(`chatGPT/checkQuestionnaire`);
//个人端获取沟通消息
export const getUserMessage = () => Axios.get(`chatGPT/getUserMessage`);

//个人端投递简历
export const personalInsertCommunicate = (postData) =>
  Axios.post("chatGPT/insertCommunicate", postData);

//个人端获取企业信息
export const getJobChain = (user_ai_chain_id) =>
  Axios.get(`chatGPT/getJobChain?user_ai_chain_id=${user_ai_chain_id}`);

//个人端获取精准推荐职位列表
export const getJobList = ({ job_city, job_district, job_street, job_title }) =>
  Axios.get(
    `chatGPT/personagePreciseRecommend?job_city=${job_city}&job_district=${job_district}&job_street=${job_street}&job_title=${job_title}`
  );

//个人端获取模糊推荐职位列表
export const getRecommendList = ({
  job_city,
  job_district,
  job_street,
  job_title,
}) =>
  Axios.get(
    `chatGPT/personageIndistinctRecommend?job_city=${job_city}&job_district=${job_district}&job_street=${job_street}&job_title=${job_title}`
  );

//个人端沟通
export const getCommunicate = () => Axios.get(`chatGPT/getCommunicate`);

//获取已发布作业列表
export const getClassEntrepot = (shop_user_class_id) =>
  Axios.get(`class/getClassEntrepot?shop_user_class_id=${shop_user_class_id}`);

// 新增班级&编辑班级
export const creactClass = (postData) =>
  Axios.post("class/insertAiClass", postData, {
    headers: {
      "Content-Type": "application/json",
    },
  });

// 学生分配班级
export const allotClass = (postData) =>
  Axios.post("class/allotClass", postData, {
    headers: {
      "Content-Type": "application/json",
    },
  });

// 删除班级
export const deleteClass = (postData) =>
  Axios.post("class/deleteClass", postData, {
    headers: {
      "Content-Type": "application/json",
    },
  });
//获取班级列表
export const getAdminClassList = () => Axios.get(`class/getClass`);

// 获取班级学生列表
export const getClassUser = (shop_user_class_id) =>
  Axios.get(`class/getClassUser?shop_user_class_id=${shop_user_class_id}`);

//删除班级学生
export const delClassUser = (postData) =>
  Axios.post("class/delClassUser", postData, {
    headers: {
      "Content-Type": "application/json",
    },
  });

//后台管理创建作业
export const creactWork = (postData) =>
  Axios.post("class/insertEntrepot", postData, {
    headers: {
      "Content-Type": "application/json",
    },
  });

//获取作业库列表
export const getAdminWorkList = () => Axios.get(`class/getEntrepot`);

//获取已发布作业列表
export const getEntrepotDetail = (shop_user_entrepot_id) =>
  Axios.get(
    `class/getEntrepotDetail?shop_user_entrepot_id=${shop_user_entrepot_id}`
  );

//后台管理删除作业
export const deleteEntrepot = (postData) =>
  Axios.post("class/deleteEntrepot", postData);

//后台管理发布作业
export const publishEntrepot = (postData) =>
  Axios.post("class/publishEntrepot", postData, {
    headers: {
      "Content-Type": "application/json",
    },
  });

//后台管理删除已发布作业
export const deleteClassEntrepot = (postData) =>
  Axios.post("class/deleteClassEntrepot", postData, {
    headers: {
      "Content-Type": "application/json",
    },
  });

//获取学生端作业列表
export const getMyClassEntrepot = () => Axios.get(`class/getMyClassEntrepot`);

//学生端获取作业详情
export const getMyEntrepotDetail = (shop_user_class_entrepot_job_id) =>
  Axios.get(
    `class/getMyEntrepotDetail?shop_user_class_entrepot_job_id=${shop_user_class_entrepot_job_id}`
  );

//学生端保存作业
export const insertDataJob = (postData) =>
  Axios.post("class/insertDataJob", postData, {
    headers: {
      "Content-Type": "application/json",
    },
  });

//学生端提交作业
export const submitPlan = (postData) =>
  Axios.post("class/submitPlan", postData, {
    headers: {
      "Content-Type": "application/json",
    },
  });

//前台指定测试结果
export const insertQuestionnaire = (postData) =>
  Axios.post("chatGPT/insertQuestionnaire", postData, {
    headers: {
      "Content-Type": "application/json",
    },
  });

//获取公司认证申请列表
export const getChainMid = (is_enable) =>
  Axios.get(`chatGPT/getChainMid?is_enable=${is_enable}`);

//批量导入职位
export const uploadJobData = (postData) =>
  Axios.post("chatGPT/uploadJobData", postData);

// 新增和编辑学生信息
export const updateStudent = (postData) =>
  Axios.post("shopApi/user/updateStudent", postData, {
    headers: {
      "Content-Type": "application/json",
    },
  });

import Axios from "axios";
import store from "../store/index";
import { notifyUser } from "../assets/js/index";

// Axios.defaults.baseURL = 'http://localhost:8001/api/shopApi/'
Axios.defaults.baseURL = "https://www.bmbai.top/api/shopApi/";

Axios.defaults.transformRequest = [
  (data) => {
    if (data instanceof FormData) return data;
    if (data && data.isNew) return data;
    console.log(data, "transformRequest");
    if (typeof data !== "undefined") {
      let ret = "";
      for (let it in data) {
        ret +=
          encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
      }
      return ret;
    }
  },
  ...Axios.defaults.transformRequest,
];
Axios.interceptors.request.use(function (config) {
  config.headers = {
    "Content-Type":
      config.headers["Content-Type"] || "application/x-www-form-urlencoded",
    // 这里记得配置跨域
    "BMBIRD-TIMESTAMP": new Date().getTime(),
    "BMBIRD-SIGN": "BMB-SHOP",
    "BMBIRD-ACCESS-TOKEN": store.state.m_login.loginData.token,
  };
  return config;
});
// 响应拦截器
Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      notifyUser("网络错误，请检查您的网络连接！", "error");
    }

    return Promise.reject(error);
  }
);

export default Axios;
